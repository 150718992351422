export const USER_ROLES = [
  {
    key: 1,
    title: 'Manager',
  },
  {
    key: 2,
    title: 'Assistant',
  },
];

export const BACKEND_URL = '/helpers/index.php';
